<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>

          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <v-stepper alt-labels
                         elevation="0">
                <v-stepper-header>
                  <v-stepper-step step="" class="active-step today-medium">
                    Póliza
                  </v-stepper-step>

                  <v-divider inset class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Simulador
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Formulario
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Firma de documentos
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Pago
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </v-col>
            <v-col class="text-center justify-center d-flex d-sm-none">
              <div class="text-h6 today-medium primary--text">
                Pago
              </div>
            </v-col>

          </v-row>

          <v-row justify="center" class="px-4">
            <v-col cols="12" md="6">

              <v-container fluid>
                <v-row align="center">
                  <v-col class="text-center" cols="12" md="4">
                    <v-img max-height="40"
                           class="my-1"
                           contain
                           src="https://app-biocredit.s3.amazonaws.com/vardi/asegurado+(2).png"
                    ></v-img>
                    <p class="mb-0 primary--text today-regular" style="font-size: 0.7em;">{{ policyType }}</p>
                    <h4 class="mb-0 today-medium">{{ policyTypeValue }}</h4>
                  </v-col>
                  <v-col class="text-center" cols="12" md="4">
                    <v-img max-height="40"
                           class="my-1"
                           contain
                           src="https://app-biocredit.s3.amazonaws.com/vardi/calendar.png"
                    ></v-img>
                    <p class="mb-0 primary--text today-regular" style="font-size: 0.7em;">{{ policyDate }}</p>
                    <h3 class="mb-0 today-medium">{{ policyDateValue }}</h3>
                  </v-col>
                  <v-col class="text-center" cols="12" md="4">
                    <v-img max-height="40"
                           class="my-1"
                           contain
                           src="https://app-biocredit.s3.amazonaws.com/vardi/dolar.png"
                    ></v-img>
                    <p class="mb-0 primary--text today-regular" style="font-size: 0.7em;">{{ policyLabel }}</p>
                    <h3 class="mb-0 today-medium">{{ policyValue }}</h3>
                  </v-col>
                </v-row>
              </v-container>

            </v-col>
          </v-row>

          <v-row justify="center" class="px-4">
            <v-col cols="12" md="9">
              <v-card class="mx-auto" color="grey lighten-4" flat style="border-radius: 15px;">

                <v-row justify="center" class="px-4">
                  <v-col cols="12">
                    <h3 class="primary--text today-light text-center mb-0">Recuerda que para activar tu póliza, debes
                      realizar un primer pago de:</h3>
                  </v-col>
                </v-row>

                <v-row justify="center" class="px-4">
                  <v-col cols="12" md="11">
                    <v-radio-group v-model="paymentType" class="mt-0">

                      <v-radio value="min"
                               key="1"
                               class="mb-0">
                        <template v-slot:label>
                          <p class="mb-0">Pago mínimo</p>
                        </template>
                      </v-radio>

                      <h2 class="ml-7 today-medium">{{ formatCurrency(minAmount) }}</h2>

                      <v-radio value="other"
                               key="2"
                               class="">
                        <template v-slot:label>
                          <currency-input
                              :rules="paymentType == 'other' ? [rules.min, rules.max] : []"
                              :options="{
                                    locale: 'en-US',
                                    currency: 'USD',
                                    currencyDisplay: 'symbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    hideNegligibleDecimalDigitsOnFocus: true,
                                    autoDecimalDigits: false,
                                    autoSign: true,
                                    useGrouping: true,
                                    accountingSign: false
                                  }"
                              :label="'Otro pago'"
                              :value="otherAmount"
                              :disabled="false"
                              class="pt-5"
                              v-on:input="calculateFee($event)"/>
                        </template>
                      </v-radio>

                      <a href="#" v-if="paymentType == 'other' && otherAmount > minAmount" @click="payDialog = true"
                         class="float-right text-right today-regular">Ver nuevo plan de pago</a>

                    </v-radio-group>

                  </v-col>
                </v-row>

              </v-card>

            </v-col>
          </v-row>

          <v-layout justify-center class="mt-4">
            <v-card-actions>
              <v-btn
                  :loading="loading"
                  :disabled="loading"
                  @click="paymentRequest"
                  color="primary"
                  class="px-12 text-capitalize vardi-button"
                  elevation="0">
                Pagar
              </v-btn>
            </v-card-actions>
          </v-layout>

        </v-container>
      </v-col>
    </v-row>

    <v-dialog
        v-model="payDialog"
        max-width="400"
        overlay-color="primary"
    >
      <v-card class="pa-4 text-center">

        <h2 class="my-3 today-medium justify-center primary--text">PLAN DE PAGO</h2>

        <v-simple-table dense
                        fixed-header>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center">
                Descripción
              </th>
              <th class="text-center">
                Valor
              </th>
              <th class="text-center">
                Fecha
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="item in payPlan"
                :key="item.name">
              <td>{{ item.description }}</td>
              <td>{{ item.value }}</td>
              <td>{{ item.date }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-card-actions class="justify-center">
          <v-btn
              color="primary"
              class="px-10 text-capitalize today-bold"
              @click="payDialog = false"
              elevation="0"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="dialog"
        max-width="350"
        overlay-color="primary"
        persistent
    >
      <v-card class="pa-4 text-center">

        <v-img max-height="50"
               class="my-4"
               contain
               src="https://app-biocredit.s3.amazonaws.com/vardi/enviar.png"
        ></v-img>

        <h2 class="my-3 today-medium justify-center primary--text">¡SOLICITUD ENVIADA!</h2>

        <h3 class="text-justify primary--text today-light line-height-1 px-4">Tú solicitud para la financiación de la
          poliza para vehículo ha sido aceptada.</h3>

        <v-card-actions class="justify-center">
          <v-btn
              color="primary"
              class="px-10 text-capitalize today-bold vardi-button"
              @click="goHome"
              elevation="0"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        right>
      {{ messageSnackbar }}
    </v-snackbar>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
import {locals} from "@/helpers/locals";
import CurrencyInput from "@/components/CurrencyInput";

export default {
  name: 'Payment',
  components: {LeftSideImage, NavBarPage, CurrencyInput},
  data() {
    return {
      dialog: false,
      loading: false,
      expires_in: 60,
      countdown: '',
      minAmount: 0,
      urlPayment: '',
      otherAmount: 0,
      snackbar: false,
      timeout: 2000,
      messageSnackbar: '',
      settings: null,
      request: null,
      policyType: '',
      policyTypeValue: '',
      policyDate: '',
      policyDateValue: '',
      policyLabel: '',
      policyValue: '',
      paymentType: 'min',
      payDialog: false,
      payPlan: [],
      feeValue: 0,
      firstFeeValue: 0,
      creditStudy: 0,
      insurance: 0,
      creditInsurance: 0,
      ivaValue: 0,
      iva: 0,
      othersFeeValue: 0,
      amortizedFee: 0,
      admin: 0,
      totalValuePolicy: 0,
      discountValue: 0,
      valueFinance: 0,
      feeNumber: 2,
      insurance_rate: 0,
      rate: 0,
      rules: {
        min: value => parseInt(value.replaceAll(',', '').replace('$', '')) > this.minAmount || 'Este valor debe ser mayor que el pago mínimo' + ' - ' + parseInt(value.replaceAll(',', '').replace('$', '')),
        max: value => parseInt(value.replaceAll(',', '').replace('$', '')) <= this.valueFinance || 'Este valor no debe ser mayor que el valor de la poliza',
      },
    }
  },
  mounted() {
  },
  methods: {
    goHome() {
      this.dialog = false;
      this.$router.push('/');
    },

    async calculateFee(event) {
      console.log('calculateFee:', event);

      try {
        this.settings = locals.getLocal('settings');
        this.request = locals.getLocal('request');
        this.otherAmount = parseInt(event, 10);

        if (this.otherAmount > this.minAmount) {
          let valueFinance = parseInt(this.valueFinance - this.otherAmount, 10);
          console.log('Valor de resta', valueFinance);
          console.log('Plazo', this.feeNumber);

         /*
          const responseSimulator = await api.get(constants.endPoints.simulator + '?insurance_policy_id=' + this.request.insurance_policy_id, true);

          console.log('getSimulatorService', responseSimulator);

          let simulator = responseSimulator.data.data;
          this.simulatorStatus = responseSimulator.data.status;
          this.simulatorMessage = responseSimulator.data.message;

          if (this.simulatorStatus) {
            this.insuranceRate = simulator.insurance_rate;
            console.log('Tasa', this.insuranceRate);
          } */

          console.log('Request', this.request.id);
          console.log('Insurance', this.creditInsurance);
          console.log('settings', this.settings);
          console.log('request', this.request.insurance_policy_id);

          const currentDate = new Date();
          const day = currentDate.getDate().toString().padStart(2, "0");
          const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
          const year = currentDate.getFullYear();
          const formatedDate = `${day}-${month}-${year}`;

          // Limpiar el array antes de agregar nuevos elementos
          this.payPlan = [];

          const responseCalculateFee = await api.post('https://tefio-vardi.bio.credit/calcularcuota', {
            data: {
              fecha: formatedDate,
              valor: valueFinance,
              plazo: this.feeNumber,
              //tasa: this.insuranceRate, // Usar la tasa real
              tasa: '27.13', // Usar la tasa real
              portafolio_id: 10040,
              idvardi: this.request.id
            }
          });

          console.log('calculo sigma ....', responseCalculateFee);

          let paymentPlan = responseCalculateFee.data.plan_pagos;
          paymentPlan.sort((a, b) => a.nro_cuota - b.nro_cuota);
          paymentPlan.forEach((value) => {
            let dataPayment = {
              description: 'Pago cuota ' + value.nro_cuota,
              value: '$ ' + (value.nro_cuota === 1 ? this.formatCurrency(this.otherAmount) : this.formatCurrency(value.cuota)),
              date: value.fecha_vence,
            };
            this.payPlan.push(dataPayment);
          });

        }
      } catch (error) {
        console.error('Error en la lógica de calculateFee:', error);
      }
    },


    calculatePaymentPlan: function () {
      this.payPlan = [];

      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();

      let paymentDays = constants.paymentDays;
      paymentDays.forEach((value) => {
        if (day > value.minDay && day < value.maxDay) {
          day = value.day;
        }
      });

      for (let i = 1; i <= this.feeNumber; i++) {
        if (month == 12) {
          month = 1;
          year += 1;
        } else {
          month += 1;
        }
        let dataPayment = {
          description: 'Pago cuota ' + i,
          value: '$ ' + (i == 1 ? this.formatCurrency(this.firstFeeValue) : this.formatCurrency(this.othersFeeValue)),
          date: (i == 1 ? date.getDate() : day) + '/' + month + '/' + year,
        };
        this.payPlan.push(dataPayment);
      }
    },
    formatCurrency: function (value) {
      var formatter = new Intl.NumberFormat('en-ES', {
        style: 'decimal',
        minimumFractionDigits: 0,
      });

      value = Math.round(value)
      return formatter.format(parseInt(value, 10));

    },
    paymentRequest() {

      if (this.paymentType == 'other') {
        if (this.otherAmount > this.minAmount && this.otherAmount <= this.valueFinance) {
          console.log('paymentRequest other', this.otherAmount);
          this.generatePayment();
        } else {
          this.snackbar = true;
          this.messageSnackbar = "Revisa el valor para continuar"
        }
      } else {
        console.log('paymentRequest min', this.minAmount);
        this.generatePayment();
      }

    },
    generatePayment() {
      this.loading = true;
      console.log(this.paymentType);
      api.post(constants.endPoints.paymentRequest, {
        user_request_id: this.request.id,
        payment_type: this.paymentType,
        other_amount: this.otherAmount,
      }, true)
          .then((response) => {
            console.log('paymentRequest', response);
            this.loading = false;

            this.urlPayment = response.data.data[0].payment_url;
            window.open(this.urlPayment, "_self");

          }, (error) => {
            console.log('paymentRequest', error);
            this.loading = false;
            this.snackbar = true;
            this.messageSnackbar = "Se ha producido un error"
          });
    }

  },
  created: function () {


    this.settings = locals.getLocal('settings');
    this.request = locals.getLocal('request');

    api.post(constants.endPoints.startPayment, {
      user_request_id: this.request.id,
    }, true)
        .then(response => {
          let dataResponse = response.data.data;

          this.minAmount = dataResponse.min_payment;
          this.policyType = dataResponse.values[0].name;
          this.policyTypeValue = dataResponse.values[0].value;
          this.policyDate = dataResponse.values[1].name;
          this.policyDateValue = dataResponse.values[1].value;
          this.policyLabel = dataResponse.values[2].name;
          this.policyValue = dataResponse.values[2].value;

          this.admin = parseInt(dataResponse.administration, 10);
          this.valueFinance = parseInt(dataResponse.total, 10);
          this.feeNumber = parseInt(dataResponse.fee_number, 10);
          this.rate = parseFloat(dataResponse.rate);
          this.iva = parseFloat(dataResponse.iva);
          this.creditInsurance = parseFloat(dataResponse.credit_insurance);
          this.creditStudy = parseInt(dataResponse.credit_study, 10);

          console.log('startPayment', dataResponse);

        }).catch(function (error) {

      console.log('errors: ', error);

      try {
        let status = error.response.status;
        if (status == 401) {
          locals.clearLocal();
          this.$router.push('/');
        }
        let errors = error.response.data.errors;
        let message = '';
        for (var [key, value] of Object.entries(errors)) {
          console.log(key + ' : ' + value);
          message = message + value + '<br>';
        }
        this.snackbarMessage = message;
        this.snackbar = true;
        this.loading = false;
      } catch (e) {
        console.log(e);
      }

    }.bind(this))

  }
}
</script>

<style lang="scss">
.v-stepper__label {
  font-size: 13px;
}

.active-step .v-stepper__label {
  color: #00205B !important;
}

.active-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
  background: #00205B !important;
}

.grey-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin-top: 29px !important;
}

.active-separator-step {
  border-color: #00205B !important;
}

.grey-separator-step {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.scroll-container {
  height: 450px;
  overflow: scroll;
  overflow-x: hidden;
}

.v-divider {
  border-width: 1px 2px 2px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 29px -82px 0 !important;
}

@media (max-width: 600px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 120px !important;
    padding: 5px !important;
  }
  .v-divider {
    visibility: hidden;
  }
}

</style>
